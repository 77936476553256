<template>
  <div class="button-group">
    <button-loan v-for="button in buttons" :key-value="button.key" :key="button.key" :selected-key="selected" @button-pressed="onButtonPressed">{{ button.text }}</button-loan>
  </div>
</template>

<script>
import ButtonLoan from "@/components/Button-loan.vue";

export default {
  name: "LoanButtonGroup",
  components: {ButtonLoan,},
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: '1',
    };
  },
  methods: {
    onButtonPressed: function (key) {
      this.selected = key;
      this.$emit('selectedChanged', key);
    }
  }

}
</script>