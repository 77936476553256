<template>
  <navbar></navbar>
  <router-view/>
  <Footer></Footer>
</template>

<script>
import Footer from "@/components/footer.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "App",
  components: {Navbar, Footer}
}
</script>