<template>
  <div class="header-home-page">
    <div class="header-home-page__wrapper">
      <h1 class="header-home-page__wrapper__title">Selecting and comparing of Credit Products</h1>
      <p class="header-home-page__wrapper__text">Profitable propositions of banks in 2024</p>
    </div>
    <img src="@/assets/page_img/title_img_two.png" alt="" class="header-home-page__img">
  </div>
  <div class="input-credit">
    <div class="input-credit__title">
      <h2 class="">Select a credit card options</h2>
    </div>
    <div class="input-credit__wrapper">
      <div class="input-credit__wrapper--credit-limit">
        <div class="input-credit__wrapper--credit-limit__display">
          <p class="input-credit__wrapper--credit-limit__display--text">Credit limit</p>
          <h3 class="input-credit__wrapper--credit-limit__display--check">97 000</h3>
        </div>
        <div class="input-credit__wrapper--credit-limit__input-box">
          <input type="range" min="0" max="200" value="100" class="input-credit__wrapper--credit-limit__input-box--input">
          <div class="input-credit__wrapper--credit-limit__input-box--scale">
            <p class="input-credit__wrapper--credit-limit__input-box--scale__number">10</p>
            <p class="input-credit__wrapper--credit-limit__input-box--scale__number">20</p>
            <p class="input-credit__wrapper--credit-limit__input-box--scale__number">50</p>
            <p class="input-credit__wrapper--credit-limit__input-box--scale__number">100</p>
            <p class="input-credit__wrapper--credit-limit__input-box--scale__number">200</p>
            <p class="input-credit__wrapper--credit-limit__input-box--scale__number">500</p>
            <p class="input-credit__wrapper--credit-limit__input-box--scale__number">1000</p>
          </div>
        </div>
        <loan-button-group :buttons="[
          { text: 'All', key: '1'},
          { text: 'Top 10', key: '2'},
          { text: 'Top 30', key: '3'},
        ]"/>
      </div>
      <div class="input-credit__wrapper--options">
        <div class="input-credit__wrapper--options__grid">
          <div class="input-credit__wrapper--options__grid--checkbox">
            <input type="checkbox" id="checkbox_1" class="input-credit__wrapper--options__grid--checkbox__checkbox-input"/>
            <label for="checkbox_1" class="input-credit__wrapper--options__grid--checkbox__checkbox-label">Free service</label>
          </div>
          <div class="input-credit__wrapper--options__grid--checkbox">
            <input type="checkbox" id="checkbox_2" class="input-credit__wrapper--options__grid--checkbox__checkbox-input"/>
            <label for="checkbox_2" class="input-credit__wrapper--options__grid--checkbox__checkbox-label">Possibility of installments</label>
          </div>
          <div class="input-credit__wrapper--options__grid--checkbox">
            <input type="checkbox" id="checkbox_3" class="input-credit__wrapper--options__grid--checkbox__checkbox-input"/>
            <label for="checkbox_3" class="input-credit__wrapper--options__grid--checkbox__checkbox-label">Document minimum</label>
          </div>
          <div class="input-credit__wrapper--options__grid--checkbox">
            <input type="checkbox" id="checkbox_4" class="input-credit__wrapper--options__grid--checkbox__checkbox-input"/>
            <label for="checkbox_4" class="input-credit__wrapper--options__grid--checkbox__checkbox-label">Cash without comission</label>
          </div>
        </div>
        <button-orange>Selected 78 cards</button-orange>
      </div>
    </div>
  </div>
  <div class="container_allbanks">
    <div class="container_allbanks__logo-banks">
      <img src="@/assets/form_svg/cinabank_img.svg" alt="">
      <img src="@/assets/form_svg/BDO_img.svg" alt="">
      <img src="@/assets/form_svg/BPI_img.svg" alt="">
      <img src="@/assets/form_svg/Metrobank_img.svg" alt="">
      <img src="@/assets/form_svg/eastwest_img.svg" alt="">
      <img src="@/assets/form_svg/security_bank_img.svg" alt="">
      <img src="@/assets/form_svg/bank_plank_img.svg" alt="">
    </div>
  </div>

  <div class="kreditka-com">
    <div class="kreditka-com__wrapper">
      <div class="kreditka-com__wrapper--header">
        <img src="@/assets/form_svg/logo_kreditka_com.svg" alt="" class="kreditka-com__wrapper--header__logo">
        <div>
          <h3 class="kreditka-com__wrapper--header__title">All banks of Cape town</h3>
          <p class="kreditka-com__wrapper--header__text">On Kreditka.com You’ll always find a bank, wich will approve your loan</p>
        </div>
      </div>
      <div class="kreditka-com__wrapper--inputs">
        <input-text></input-text>
        <input-text></input-text>
      </div>
    </div>
  </div>

  <div class="loans">
    <div class="loans__header">
      <h2 class="loans__header--title">Loan with accurate conditions </h2>
      <p class="loans__header--text">Find your best options on Kreditka.com</p>
    </div>
    <div class="loans__wrapper">
      <div class="loans__wrapper--bloc-one">
        <h3 class="loans__wrapper--bloc-one__title">We’ll find a loan for you</h3>
        <div class="loans__wrapper--bloc-one__list">
          <p class="loans__wrapper--bloc-one__list--text">We’ll help you a loan for your parameteres with the best % rate, fast</p>
          <p class="loans__wrapper--bloc-one__list--text">We use only relevant information about banks propositions</p>
          <p class="loans__wrapper--bloc-one__list--text">All data is reserved and proceed in syphered conditions</p>
        </div>
      </div>
      <div class="loans__wrapper--bloc-two">
        <h3 class="loans__wrapper--bloc-two__title">Fill your own blank</h3>
        <div class="loans__wrapper--bloc-two__parameter">
          <div class="loans__wrapper--bloc-two__parameter--circle">1</div>
          <p class="loans__wrapper--bloc-two__parameter--text">Send your application within martch 22 - june 23 </p>
        </div>
        <div class="loans__wrapper--bloc-two__parameter">
          <div class="loans__wrapper--bloc-two__parameter--circle">2</div>
          <p class="loans__wrapper--bloc-two__parameter--text">Apply for a loan before the 9th of july</p>
        </div>
        <button-orange>All reviews</button-orange>
      </div>
    </div>
  </div>

  <div class="client-reviews">
    <div class="client-reviews__header">
      <h2 class="client-reviews__header--title">Client reviews</h2>
      <p class="client-reviews__header--text">Latest reviews about banking products</p>
    </div>
    <div class="client-reviews__wrapper">
      <div class="client-reviews__wrapper--box">
        <div class="client-reviews__wrapper--box__reviews">
          <img src="@/assets/form_svg/five_stars.svg" alt="" class="client-reviews__wrapper--box__reviews--grade">
          <p class="client-reviews__wrapper--box__reviews--text">I have always considered this bank to be reliable, serious and with a good I have never seen such a cashback...r a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with it this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...</p>
        </div>
        <div class="client-reviews__wrapper--box__client-avatar">
          <div class="client-reviews__wrapper--box__client-avatar--img">
            <img src="@/assets/page_img/client_one.png" alt="">
          </div>
          <h3 class="client-reviews__wrapper--box__client-avatar--name">American Names</h3>
        </div>
      </div>
      <div class="client-reviews__wrapper--box">
        <div class="client-reviews__wrapper--box__reviews">
          <img src="@/assets/form_svg/four_stars.svg" alt="" class="client-reviews__wrapper--box__reviews--grade">
          <p class="client-reviews__wrapper--box__reviews--text">I have always considered this bank to be reliable, serious and with a good I have never seen such a cashback...r a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with it this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...</p>
        </div>
        <div class="client-reviews__wrapper--box__client-avatar">
          <div class="client-reviews__wrapper--box__client-avatar--img">
            <img src="@/assets/page_img/client_two.png" alt="">
          </div>
          <h3 class="client-reviews__wrapper--box__client-avatar--name">American Names</h3>
        </div>
      </div>
      <div class="client-reviews__wrapper--box">
        <div class="client-reviews__wrapper--box__reviews">
          <img src="@/assets/form_svg/five_stars.svg" alt="" class="client-reviews__wrapper--box__reviews--grade">
          <p class="client-reviews__wrapper--box__reviews--text">I have always considered this bank to be reliable, serious and with a good I have never seen such a cashback...r a credit card from this bank and was pleased with its... In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with it this bank and was pleased with its...In June I applied for a credit card from this bank and was pleased with its...</p>
        </div>
        <div class="client-reviews__wrapper--box__client-avatar">
          <div class="client-reviews__wrapper--box__client-avatar--img">
            <img src="@/assets/page_img/client_three.png" alt="">
          </div>
          <h3 class="client-reviews__wrapper--box__client-avatar--name">American Names</h3>
        </div>
      </div>
      <div class="client-reviews__wrapper--button"><button-orange>All reviews</button-orange></div>
    </div>
  </div>

  <div class="privileges">
    <div class="privileges__header">
      <h2 class="privileges__header--title">Our priviledges</h2>
    </div>
    <div class="privileges__wrapper">
      <div class="privileges__wrapper--box">
        <div class="privileges__wrapper--box__title">
          <img src="@/assets/form_svg/priviledges_img_one.svg" alt="" class="privileges__wrapper--box__title--img">
          <h3 class="privileges__wrapper--box__title--text">Wide choice</h3>
        </div>
        <p class="privileges__wrapper--box__description">Access to wide variety of finantial products from different banks</p>
      </div>
      <div class="privileges__wrapper--box">
        <div class="privileges__wrapper--box__title">
          <img src="@/assets/form_svg/priviledges_img_two.svg" alt="" class="privileges__wrapper--box__title--img">
          <h3 class="privileges__wrapper--box__title--text">Apply where it’s convenient</h3>
        </div>
        <p class="privileges__wrapper--box__description">You can choose going to an office or do everything online</p>
      </div>
      <div class="privileges__wrapper--box">
        <div class="privileges__wrapper--box__title">
          <img src="@/assets/form_svg/priviledges_img_three.svg" alt="" class="privileges__wrapper--box__title--img">
          <h3 class="privileges__wrapper--box__title--text">No hidden conditions</h3>
        </div>
        <p class="privileges__wrapper--box__description">Neutral and objective information about accessable products</p>
      </div>
    </div>
  </div>
</template>

<script>

import LoanButtonGroup from "@/components/Loan-buttons-group.vue";
import ButtonOrange from "@/components/button-orange.vue";
import InputText from "@/components/Input_text.vue";

export default {
  name: 'HomeView',
  components: {
    InputText,
    ButtonOrange,
    LoanButtonGroup
  }
}
</script>
