<template>
  <div class="navbar">
    <div class="navbar__wrapper">
      <img src="@/assets/form_svg/navbar_logo.svg" alt="" class="navbar__wrapper--logo">
      <div class="navbar__wrapper--links">
        <router-link to="" class="navbar__wrapper--links__btn">Credit cards</router-link>
        <router-link to="" class="navbar__wrapper--links__btn">Loans</router-link>
        <router-link to="" class="navbar__wrapper--links__btn">Loans</router-link>
        <router-link to="" class="navbar__wrapper--links__btn">Products</router-link>
        <router-link to="" class="navbar__wrapper--links__btn">Products</router-link>
      </div>
      <button class="navbar__wrapper__button"><img src="@/assets/form_svg/registration_img.svg" alt=""></button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Navbar",
  }
</script>