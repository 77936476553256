<template>
  <button class="btn-orange">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonOrange',
}
</script>