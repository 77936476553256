<template>
  <button @click="buttonClick" class="btn-loan" :class="(selectedKey === keyValue && keyValue !== '') ? 'selected' : ''">
    <slot/>
  </button>
</template>

<script>

export default {
  name: "ButtonLoan",
  props: {
    selectedKey: {
      type: String,
      default: '',
    },
    keyValue: {
      type: String,
      default: '',
    }
  },
  methods: {
    buttonClick: function () {
      this.$emit('buttonPressed', this.keyValue);
    },
  },

}
</script>

